import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input'];

  static values = {
    value: String,
  }

  set() {
    this.inputTarget.value = this.valueValue;
  }
}
