import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  makeOptionsReadonly() {
    Array.from(this.element.options).forEach((option) => {
      if (option.value !== this.element.value) {
        option.setAttribute('disabled', true);
      }
    });
  }
}
