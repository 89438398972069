import { Controller } from '@hotwired/stimulus';

/**
 * This controller is used to manage the alert message when the user
 * choose the today date in the date picker.
 */
export default class extends Controller {
  static targets = ['datePicker', 'todayAlert', 'submitButton'];

  static values = { soonestPossibleDate: String };

  alertDate() {
    const selectedDate = new Date(this.datePickerTarget.value);
    const soonestPossibleDate = new Date(this.soonestPossibleDateValue);

    if (selectedDate < soonestPossibleDate) {
      this.todayAlertTarget.classList.remove('hidden');
      this.submitButtonTarget.disabled = true;
    } else {
      this.todayAlertTarget.classList.add('hidden');
      this.submitButtonTarget.disabled = false;
    }
  }
}
