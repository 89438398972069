import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['element'];

  static values = {
    klass: String,
  }

  remove() {
    this.elementTargets.forEach((element) => {
      element.classList.remove(this.klassValue);
    });
  }

  add() {
    this.elementTargets.forEach((element) => {
      element.classList.add(this.klassValue);
    });
  }

  toggle() {
    this.elementTargets.forEach((element) => {
      element.classList.toggle(this.klassValue);
    });
  }
}
