import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

window.Stimulus = Application.start();
const context = require.context('../controllers', true, /_controller\.js$/);
const contextComponents = require.context('../../../components', true, /_controller\.js$/);

window.Stimulus.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents),
  ),
);
