import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'total'];

  sumInputValues() {
    const inputs = this.inputTargets;
    const values = inputs.map((input) => parseFloat(input.value) || 0);
    const total = this.totalTarget;
    total.value = values.reduce((a, b) => a + b, 0);
  }
}
