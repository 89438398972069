import { Controller } from '@hotwired/stimulus';

// Toggles between the French commune and an unstructured city form, depending
// on the selected country.
//
// Connects to data-controller="birth-places"
export default class extends Controller {
  static targets = [
    'select',
    'communeForm',
    'freeformCity',
  ]

  static values = { franceId: String }

  updateCityChoice() {
    const isUnselected = !this.selectTarget.value;
    const isFranceSelected = (this.selectTarget.value === this.franceIdValue);

    this.communeFormTarget.hidden = isUnselected || !isFranceSelected;
    this.freeformCityTarget.hidden = isUnselected || isFranceSelected;
  }

  connect() {
    this.updateCityChoice();
  }
}
