import { Controller } from '@hotwired/stimulus';

/**
 * This controller manages the display of a modal, and the one of a potential parent modal
 */
export default class extends Controller {
  initialize() {
    const trigger = document.querySelector(`[data-trigger-modal=${this.element.dataset.triggerName}]`);
    this.addA11yAttributes(trigger);
    this.applyEventsHandler(trigger);

    // find a parent modal, looking from the trigger element up
    this.parentModal = trigger.closest('dialog');

    if (this.parentModal == null) {
      this.element.querySelector('.back_to_parent').remove();
    }

    // Also add a11y attributes to modal__link with data-action
    this.element.querySelectorAll('.modal__link[data-action]').forEach((e) => this.addA11yAttributes(e));
  }

  open() {
    this.element.showModal();
    // only this modal should stay visible !
    this.element.classList.remove('hidden');

    // if we have a parent, hide it
    this.parentModal?.classList.add('hidden');

    // prevent the main page from scrolling
    document.querySelector('body:not(.noscroll)')?.classList.add('noscroll');
  }

  close() {
    // close the current modal
    this.element.close();

    // if we have a parent, show it
    this.parentModal?.classList.remove('hidden');
  }

  closeAll() {
    const dialogs = document.querySelectorAll('dialog');
    Array.from(dialogs).forEach((d) => {
      d.close();
    });

    /* the last modal is closed ! make body scrollable again ! */
    document.querySelector('body.noscroll')?.classList.remove('noscroll');
  }

  handleMouse(modal, event) {
    modal.open();
  }

  handleKeyboard(modal, event) {
    switch (event.key) {
      case 'Enter':
      case ' ':
      case 'Spacebar':
        modal.open();
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  addA11yAttributes(elem) {
    // https://stackoverflow.com/questions/6562407/what-does-role-button-mean
    // make it focusable
    elem.setAttribute('tabIndex', '0');
    elem.setAttribute('role', 'button');
  }

  applyEventsHandler(elem) {
    // make it clickable
    elem.addEventListener('click', this.handleMouse.bind(null, this));
    // make it respond to keyboard activation
    elem.addEventListener('keydown', this.handleKeyboard.bind(null, this));
  }
}
