import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  submit() {
    const form = this.element.form || this.element.closest('form');
    // Set this data attribute to prevent scrolling
    form.setAttribute('data-turbo-action', 'replace');
    form.requestSubmit();
  }
}
