import { Controller } from '@hotwired/stimulus';

/**
 * This controller is used to manage actions on a spinner UI element
 */
export default class extends Controller {
  static targets = ['spinner'];

  static values = {
    step: Number,
    type: String,
  }

  connect() {
    // Update value on connect
    this.#updateSpinnerTarget();
  }

  #currentVal() {
    if (this.typeValue === 'integer') {
      return parseInt(this.spinnerTarget.value, 10) || 0;
    }
    if (this.typeValue === 'decimal') {
      return parseFloat(this.spinnerTarget.value) || 0.0;
    }
    throw new Error('Invalid type value');
  }

  increment() {
    this.changeValueBy(this.stepValue);
  }

  decrement() {
    this.changeValueBy(-this.stepValue);
  }

  changeValueBy(amount, precision = 1) {
    let newValue;
    if (this.typeValue === 'integer') {
      newValue = Math.max(0, this.#currentVal() + amount);
      this.spinnerTarget.value = newValue;
    } else if (this.typeValue === 'decimal') {
      newValue = Math.max(0.0, this.#currentVal() + amount);
      this.spinnerTarget.value = newValue.toFixed(precision);
    } else {
      throw new Error('Invalid type value for change');
    }
    this.#updateSpinnerTarget();
  }

  #updateSpinnerTarget() {
    this.spinnerTarget.dispatchEvent(new Event('input'));
  }
}
