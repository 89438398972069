import { Controller } from '@hotwired/stimulus';

/**
 * This controller is used to manage the readonly status + the values
 * of the fields civility, firstname, lastname & companyName
 * in the login subform in the suscription context.
 */
export default class extends Controller {
  static targets = ['civility', 'firstName', 'lastName', 'login'];

  loginTargetConnected() {
    const info = this.loginTarget.dataset;
    if (info.firstName.trim().length > 0) {
      this.firstNameTarget.value = info.firstName;
    }
    if (this.firstNameTarget.value.length > 0) {
      this.firstNameTarget.readOnly = true;
    }
    if (info.lastName.trim().length > 0) {
      this.lastNameTarget.value = info.lastName;
    }
    if (this.lastNameTarget.value.length > 0) {
      this.lastNameTarget.readOnly = true;
    }
    if (info.civility.trim().length > 0) {
      this.civilityTarget.value = info.civility;
    }
    if (this.civilityTarget.value.length > 0) {
      this.makeCivilityTargetReadOnly();
    }
  }

  makeCivilityTargetReadOnly() {
    if (this.civilityTarget.tagName === 'SELECT') {
      // We need to broadcast the readonly event to the select element in a Promise
      // to be sure the controller readonly-select is actually connected to the select element.
      Promise.resolve().then(() => {
        const event = new Event('enercoop:readonly-select');
        window.dispatchEvent(event);
      });
    }
  }
}
