import { Controller } from '@hotwired/stimulus';

const SHARE_PRICE = 100;

export default class extends Controller {
  static targets = ['unit'];

  updateUnit(event) {
    const sharesCount = event.target.value;
    const amount = sharesCount * SHARE_PRICE;
    const partLabel = sharesCount > 1 ? 'parts' : 'part';
    this.unitTarget.innerText = `${partLabel} pour ${amount} €`;
  }
}
