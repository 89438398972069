import { Controller } from '@hotwired/stimulus';

const onFormSubmit = (e) => {
  if (!e.detail.success) {
    window.scrollTo(0, 0);
  }
};

export default class extends Controller {
  // eslint-disable-next-line class-methods-use-this
  connect() {
    document.addEventListener('turbo:submit-end', onFormSubmit);
  }

  // eslint-disable-next-line class-methods-use-this
  disconnect() {
    document.removeEventListener('turbo:submit-end', onFormSubmit);
  }
}
