import { Controller } from '@hotwired/stimulus';

/**
 *  This controller is for few lines of CSS:
 *  ```css
 *  .section-to-show-or-hide {
 *    display: none;
 *  }
 *
 *  .toggle label:has(input:checked) ~ .section-to-show-or-hide {
 *    display: block;
 *  }
 *   ```
 */
export default class extends Controller {
  static targets = ['input', 'section'];

  connect() {
    this.toggle();
  }

  toggle(e) {
    const checked = e ? e.target.checked : this.inputTarget.checked;
    if (checked) {
      this.sectionTarget.style.display = 'block';
    } else {
      this.sectionTarget.style.display = 'none';
    }
  }
}
